<template>
    <modal ref="modalCambiarNombreAdjunto" titulo="Cambiar nombre" adicional="Guardar" @adicional="cambiarNombre">
        <div class="row justify-content-center m-3 f-15">
            <div class="col-10 my-2">
                <p class="input-label-top">Nombre del archivo</p>
                <el-input v-model="inputNuevoNombre" placeholder="Nombre del archivo" size="small" class="w-100" />
            </div>
        </div>
    </modal>
</template>

<script>
export default {
    data(){
        return {
            inputNuevoNombre: '',
        }
    },
    methods: {
        toggle(){
            this.$refs.modalCambiarNombreAdjunto.toggle()
        },
        cambiarNombre(){
            this.toggle()
        }
    }
}
</script>

<style lang="css" scoped>
</style>
